<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="12" sm="8" md="8">
        <v-radio-group
          v-model="option"
          row
          mandatory
          @change="option_change"
          dense
        >
          <v-radio label="Encours" value="1"></v-radio>
          <v-radio label="Echues a date" value="2"></v-radio>
          <v-menu
            v-model="datepicker1"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                autocomplete="off"
                :value="formatDate(limite)"
                @click:clear="limite = null"
                label="Date Echéance"
                prepend-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
                dense
                :disabled="option != 2"
                readonly
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="limite"
              @input="datepicker1 = false"
            ></v-date-picker>
          </v-menu>

          <v-radio label="Reglées" value="3"></v-radio>
          <v-radio label="Sit.Ini." value="4"></v-radio>
          <v-radio label="Tous" value="5"> </v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" md="8">
        <listitemspage
          :qSelect="Qselect"
          :qTotal="Qtotal"
          :qSelectall="Qselectall"
          :PageResponse="'facts_list'"
          :AllResponse="'allfact'"
          :TotalResponse="'facts_tot'"
          :headers="headers"
          :field_list="['FACT_DATE', 'NOFACT']"
          :filename="'factures'"
          :sheetname="'factures'"
          @rowselect="FactChange"
          @open="OpenFact"
          :showedit="false"
          :key="klist"
          :Add="false"
          :del="false"
          :Total="true"
          :WhereExt="WhereExt"
          :laoding="loading"
          :expire1="null"
          :list_options="false"
        >
        </listitemspage>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <listitems
          :list="detail_list"
          :title="'Reglements'"
          :qDelete="Qdetaildelete"
          :headers="detailheaders"
          :master="false"
          :key="aff"
          :add_disable="
            facture.statut_id >= 14 &&
            facture.mont_ttc - sumField('montant') > 0
          "
          :add="false"
          :del="false"
          :del_disable="paiement.avance == '1'"
          :Update="false"
          :showedit="false"
          @open="OpenPayeForm"
          @rowselect="PayeChange"
          @delete="get_data"
        >
        </listitems>
      </v-col>
    </v-row>

    <factpayeadd
      :item="paiement"
      :fact="facture"
      :items="detail_list"
      :key="kp"
      :showpaye="!isPayeClosed"
      @close="closePayeForm"
      @change="get_data"
      :payes_list="payes_list"
      :mvm="false"
    >
    </factpayeadd>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
    <confirmdialog ref="confirm" :is_motif="is_motif" @motif="get_motif" />
  </v-container>
</template>

<script>
import ALLFACT from "../graphql/Facture/ALLFACT.gql";
import FACTS_SUIVI from "../graphql/Facture/FACTS_SUIVI.gql";
import FACTS_SUIVI_TOTAL from "../graphql/Facture/FACTS_SUIVI_TOTAL.gql";
import DELETE_PAYE_DETAIL from "../graphql/Paiement/DELETE_PAYE_DETAIL.gql";
import ALLPAYE from "../graphql/Facture/ALLPAYE.gql";
//import { exportToXLSX } from "../utils/export";

export default {
  components: {
    listitems: () => import("../components/ListItems.vue"),
    listitemspage: () => import("../components/ListItemsPages.vue"),
    factpayeadd: () => import("../components/FactPayeAdd.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
  },
  props: {
    fact_type: Number,
  },
  data: () => ({
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    klist: 100,
    option: "1",
    datepicker1: false,
    limite: null,
    selitem: {},
    aff: 0,
    is_motif: false,

    headers: [
      {
        text: "Type",
        value: "type_label",
        enum: "TYPE_LABEL",
        slot: "chip",
        color: "type_color",
        list: ["A", "F"],
        selected: true,
      },

      {
        text: "Ref.",
        value: "nofact",
        enum: "NOFACT",
        selected: true,
        totlib: true,
        slot: "href",
        width: "110px",
      },

      {
        text: "Date",
        value: "fact_date",
        enum: "FACT_DATE",
        slot: "date",
        selected: true,
        width: "105px",
      },
      {
        text: "Code",
        value: "code_tier",
        enum: "CODE_TIER",
        selected: false,
      },
      {
        text: "Tier",
        align: "text-left",
        value: "tier_name",
        enum: "TIERS_NAME",
        selected: true,
      },
      {
        text: "Type",
        value: "dp_type",
        selected: true,
        hiden: true,
        width: "120px",
      },
      {
        text: "Avant le",
        value: "date_limite",
        enum: "DATE_LIMITE",
        slot: "date",
        selected: false,
        expire: true,
        width: "120px",
      },
      {
        text: "H.T.",
        value: "mont_ht",
        enum: "MONT_HT",
        align: "end",
        slot: "cur",
        selected: true,
        result: "mont_ht",
        width: "150px",
      },
      {
        text: "T.T.C.",
        value: "mont_ttc",
        enum: "MONT_TTC",
        align: "end",
        slot: "cur",
        selected: true,
        result: "mont_ttc",
        width: "150px",
      },
      {
        text: "Reglé",
        value: "ttc_paye",
        enum: "TTC_PAYE",
        align: "end",
        slot: "cur",
        selected: true,
        result: "ttc_paye",
        width: "150px",
      },
      {
        text: "Reste",
        value: "ttc_reste",
        enum: "TTC_RESTE",
        align: "end",
        slot: "cur",
        result: "ttc_reste",
        selected: true,
        width: "150px",
      },
      {
        text: "Statut",
        align: "text-left",
        value: "status_name",
        enum: "STATUS_NAME",
        selected: true,
        list: [
          "Brouillon",
          "Attente Reglement",
          "Reglé",
          "Att. Dossier Paiement",
        ],
      },
    ],
    detailheaders: [
      {
        text: "No",
        value: "no",
        selected: true,
      },
      {
        text: "Code",
        value: "nopaye",
        selected: true,
      },
      {
        text: "Date",
        value: "paye_date",
        slot: "date",
        selected: true,
      },
      {
        text: "Montant",
        value: "montant_paye",
        selected: true,
        slot: "cur",
        align: "end",
      },
      {
        text: "Reglé",
        value: "montant",
        selected: true,
        slot: "cur",
        align: "end",
      },
    ],
    isFactClosed: true,
    isPayeClosed: true,
    kp: 100,
    paiement: {},
    facture: {},
    changed: false,
    payes_list: [],
    fact_list: [],
    loading: false,
    motif: null,
    month: null,
    WhereExt: [],
  }),

  computed: {
    Qselect() {
      return FACTS_SUIVI;
    },
    Qtotal() {
      return FACTS_SUIVI_TOTAL;
    },
    Qselectall() {
      return ALLFACT;
    },
    Qdetaildelete() {
      return DELETE_PAYE_DETAIL;
    },
    detail_list: function () {
      return this.facture.id > 0 ? this.facture.paiements : [];
    },
  },
  watch: {
    fact_type: {
      handler() {
        // this.facture = {};
      },
      deep: true,
    },
  },
  created() {
    this.limite = this.$store.state.today;
  },

  mounted() {
    this.option_change();
    this.get_data();
  },

  methods: {
    formatDate(date) {
      if (!date) return null;
      let d = new Date(date).toISOString().substr(0, 10);
      const [year, month, day] = d.split("-");
      return `${day}/${month}/${year}`;
    },
    option_change() {
      switch (this.option) {
        case "1":
          this.WhereExt = [
            { column: "TTC_RESTE", operator: "GT", value: 0 },
            { column: "INI", value: "0" },
            { column: "STATUT_ID", value: "14" },
            {
              column: "FACT_TYPE",
              operator: "IN",
              value:
                this.fact_type == 1 || this.fact_type == 2
                  ? ["1", "2"]
                  : ["3", "4"],
            },
          ];

          break;
        case "2":
          this.WhereExt = [
            { column: "DATE_LIMITE", operator: "LTE", value: this.limite },
            { column: "TTC_RESTE", operator: "GT", value: 0 },
            { column: "INI", value: "0" },
            { column: "STATUT_ID", value: "14" },
            {
              column: "FACT_TYPE",
              operator: "IN",
              value:
                this.fact_type == 1 || this.fact_type == 2
                  ? ["1", "2"]
                  : ["3", "4"],
            },
          ];

          break;
        case "3":
          this.WhereExt = [
            { column: "MONT_TTC", operator: "GT", value: 0 },
            { column: "TTC_RESTE", operator: "EQ", value: 0 },
            { column: "INI", value: "0" },
            { column: "STATUT_ID", value: "14" },
            {
              column: "FACT_TYPE",
              operator: "IN",
              value:
                this.fact_type == 1 || this.fact_type == 2
                  ? ["1", "2"]
                  : ["3", "4"],
            },
          ];

          break;
        case "4":
          this.WhereExt = [
            { column: "INI", value: "1" },
            { column: "STATUT_ID", value: "14" },
            {
              column: "FACT_TYPE",
              operator: "IN",
              value:
                this.fact_type == 1 || this.fact_type == 2
                  ? ["1", "2"]
                  : ["3", "4"],
            },
          ];

          break;
        case "5":
          this.WhereExt = [
            {
              column: "FACT_TYPE",
              operator: "IN",
              value:
                this.fact_type == 1 || this.fact_type == 2
                  ? ["1", "2"]
                  : ["3", "4"],
            },
          ];

          break;
        default:
          break;
      }
      this.klist++;
    },
    async get_data() {
      let v;
      this.loading = true;

      const d = new Date(this.$store.state.today);
      this.month = d.getMonth() + 1;

      v = {
        VarScope: 1,
        TypeScope: this.fact_type == 3 || this.fact_type == 4 ? 3 : 1,
      };
      let r = await this.requette(ALLPAYE, v);
      if (r) this.payes_list = r.allpaye;

      this.headers[4].text =
        this.fact_type == 3 || this.fact_type == 4 ? "Client" : "Fournisseur";
      this.headers[5].hiden = this.fact_type == 3 || this.fact_type == 4;
      this.facture = {};
      this.aff++;
    },

    get_motif(m) {
      this.motif = m;
    },
    async maj(query, v) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
          this.progress = false;
        });
      return r;
    },

    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
    async requette(query, v) {
      let r;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    sumField(key) {
      // sum data in give key (property)
      return this.detail_list.reduce((a, b) => a + (b[key] || 0), 0);
    },
    PayeChange(item) {
      this.paiement = item;
    },

    OpenPayeForm(item) {
      this.kp++;
      this.paiement = item;
      this.isPayeClosed = false;
      this.aff++;
    },
    closePayeForm() {
      this.isPayeClosed = true;
    },
    FactChange(item) {
      this.aff++;
      this.selitem = item;
      this.facture = item;
    },
    async OpenFact(item) {
      //verifier l'autoriasation avant
      let auth =
        (item.fact_type == 1 && this.$store.state.auth.includes("01014")) ||
        (item.fact_type == 2 && this.$store.state.auth.includes("01015")) ||
        (item.fact_type == 3 && this.$store.state.auth.includes("01012")) ||
        (item.fact_type == 4 && this.$store.state.auth.includes("01013")) ||
        this.$store.state.isadmin;
      if (auth)
        await this.$router
          .push({
            name:
              item.fact_type == 1
                ? "facturations_f"
                : item.fact_type == 2
                ? "facturations_fa"
                : item.fact_type == 3
                ? "facturations_c"
                : item.fact_type == 4
                ? "facturations_ca"
                : "",
            params: {
              facture: item,
              name:
                item.fact_type == 1 || item.fact_type == 2
                  ? "suivi_factures_f"
                  : "suivi_factures_c",
            },
          })
          .catch(() => {});
    },
  },
};
</script>
